import React from "react";
import '../../App.css';
import logo from '../../logo.png';

function Home(){
    return(
        <div className="App">
            <header className="App-header">
                <h1 className="App-title">Serch Onodera</h1>
                <h2 className="App-title">main site in development</h2>
            </header> 
        </div>
    );
}

export default Home;