import React from "react";
import { useEffect, useState } from "react";
import '../../game_page_style.css'

function GA_policies(){
    useEffect(()=>{
        document.title="GA privacy policies"
    },[]);

    return (
        <div className="privacy_page">
            <section className="horizontal sticky-material">
                <img className="icon-policies" src={require('../../../../assets/game_images/ga_portada.png')}></img>
                <h1 className="title-fixed">Gisselle's Adventure privacy policies</h1>
            </section>
            <header className="privacy_header">
                <img className="privacy_header_img" src={require("../../../../assets/game_images/ga_header.png")} alt="prviacy policies header"/>
            </header>
            <section className="generic-section-container container-horizontal">
                <div className="body-privacy">
                    <h1>Privacy policy for the use of the Gisselle's Adventure application on Epic Games.</h1>
                    <span>last modified november 12th 2024</span>
                    <br></br>
                    <br></br>
                    <div>
                        <h3>
                        Respect for your privacy is fully guaranteed. By installing the application you indicate that you know and understand the following points of the privacy policy.
                        </h3>
                        <br></br>
                        <br></br>
                        <ol>
                            <li>Through Gisselle's Adventure, no personal data is collected from the user.</li>
                            <li>The App developer, not Epic Games, is solely responsible for Gisselle's Adventure and its content.</li>
                            <li>IP addresses are not recorded.</li>
                            <li>User email accounts are not accessed.</li>
                            <li>The app does not store data or track usage times and schedules.</li>
                            <li>The app does not store information about your device, such as crashes, system activity, hardware settings, browser type, or browser language.</li>
                            <li>The app only uses your device's operating system name to provide specialized mechanics for the device. For example, on Android:
                                <ul>
                                <li>display of touch controls,</li>
                                <li>adjustment of special effects, such as the omission of shaders for performance purposes.</li>
                                </ul>
                            </li>
                            <li>The app does not access your contacts or calendars.</li>
                            <li>The app does not collect information about your current location.</li>
                            <li>Maintenance and support: The app developer, not Epic Games, is responsible for providing maintenance and support.</li>
                            <li>Contact: If you have any questions about the Privacy Policy or to report any violations of the policy, please email: gissellesadventure@gmail.com</li>
                        </ol>

                    </div>
                </div>
            </section>

        </div>
    );
}

export default GA_policies;