import logo from './logo.svg';
import './App.css';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import Home from './pages/home/'
import GissellesAdventure from './pages/games/gisselles_adventure';
import GA_policies from './pages/games/gisselles_adventure/ga_policies';

function App() {
  return (
    <div className="App">
    {/* <Home></Home> */}
    {/* <GissellesAdventure></GissellesAdventure> */}
      <Router>
          <Routes>
            {/* <Route path="/" element={<GissellesAdventure />} /> */}
            <Route path="/" element={<Home />} />
            <Route path="home/" element={<Home/>} exact/>
            <Route path="games/gisselles_adventure" element={<GissellesAdventure/>}/>
            <Route path="games/gisselles_adventure/privacy_policies" element={<GA_policies/>}/>
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
      </Router>
    </div>
  );
}

export default App;
